<template>
  <div class="box-faultr" id="contentBox" ref="contentBox">
    <div id="headerBox" ref="headerRef" class="header">
      <div :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"></div>
      <div class="navBar">
        <img
          class="back"
          @click="goback"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="title">故障诊断</div>
      </div>
    </div>
    <div
      class="header-placehold"
      :style="{
        height: `${headerH + appBarHeight}px`,
        backgroundColor: 'white',
      }"
    ></div>
    <!-- 车辆 -->
    <div class="top-vin">
      <div class="text">
        车辆 <span class="vin">{{ vinId }}</span>
      </div>
      <div class="location">
        <img src="@/assets/faultrReporting/location.png" alt="" />
        <span>{{ parkName }}</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="content">
      <van-form>
        <!-- 故障时间 -->
        <div class="time-picker">
          <van-field
            readonly
            clickable
            disabled
            name="dateTime"
            :value="form.timeValue"
            label="故障时间"
            placeholder="请选择故障时间"
            :rules="[{ required: true }]"
          />
          <!-- @click="timePickerShow = true" -->
          <timePicker
            :values="form.timeValue"
            ref="popup"
            :showPicker="timePickerShow"
            @confirm="confirmOn"
            @cancel="timePickerShow = false"
          />
        </div>
        <!-- 故障类型 -->
        <div class="selector">
          <van-field
            readonly
            clickable
            name="moduleTypeValue"
            v-model="form.moduleTypeValue"
            label="故障类型"
            placeholder="请选择故障类型"
            @click-input="showPickerType = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="showPickerType" position="bottom">
            <van-picker
              value-key="dictTypeValue"
              show-toolbar
              :columns="faultTypeColumns"
              :default-index="
                form.moduleType
                  ? faultTypeColumns.findIndex((item) => item.dictType == form.moduleType)
                  : 0
              "
              @confirm="onConfirmType"
              @cancel="showPickerType = false"
              title="选择故障类型"
            />
          </van-popup>
        </div>
        <!-- 主故障 -->
        <div class="selector" v-if="this.typeValueID">
          <van-field
            readonly
            clickable
            name="mainQuestionId"
            v-model="form.mainfaultValue"
            label="主故障"
            placeholder="请选择主故障"
            @click-input="showPickerMainfault = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="showPickerMainfault" position="bottom">
            <van-picker
              value-key="dictTypeValue"
              show-toolbar
              :columns="mainfaultColumns"
              :default-index="
                form.mainfaultType
                  ? mainfaultColumns.findIndex((item) => item.dictType == form.mainfaultType)
                  : 0
              "
              @confirm="onConfirmMainfault"
              @cancel="showPickerMainfault = false"
              title="选择主故障"
            />
          </van-popup>
        </div>
        <!-- 故障描述 -->
        <div class="textDes" ref="remark">
          <van-field
            name="remark"
            v-model.trim="form.message"
            rows="3"
            autosize
            label="具体描述"
            type="textarea"
            maxlength="500"
            placeholder="可更具体地描述故障，最多500字"
            @focus="handleInputFocus('remark')"
            @blur="handleInputBlur"
            :rules="[{ required: true }]"
          />
        </div>

        <!-- 故障责任判定 -->
        <div class="selector">
          <van-field
            class="selector"
            readonly
            clickable
            name="faultLiabilityValue"
            v-model="form.faultLiabilityValue"
            label="故障责任判定"
            placeholder="请选择故障责任判定"
            @click-input="faultLiability.isShow = true"
            :rules="[{ required: true }]"
            :label-width="200"
          />
          <van-popup v-model="faultLiability.isShow" position="bottom">
            <van-picker
              value-key="dictTypeValue"
              show-toolbar
              :columns="faultLiability.columns"
              @confirm="onConfirmFaultLiability"
              @cancel="faultLiability.isShow = false"
              :default-index="
                form.faultLiabilityType
                  ? faultLiability.columns.findIndex(
                      (item) => item.dictType == form.faultLiabilityType
                    )
                  : 0
              "
              title="选择故障责任判定"
            />
          </van-popup>
        </div>
        <!-- 处理方案 -->
        <div class="selector">
          <van-field
            class="selector"
            readonly
            clickable
            name="solutionValue"
            v-model="form.solutionValue"
            label="处理方案"
            placeholder="请选择处理方案"
            @click-input="solution.isShow = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="solution.isShow" position="bottom">
            <van-picker
              value-key="dictTypeValue"
              show-toolbar
              :columns="solution.columns"
              :default-index="
                form.solutionType
                  ? solution.columns.findIndex((item) => item.dictType == form.solutionType)
                  : 0
              "
              @confirm="onConfirmSolution"
              @cancel="solution.isShow = false"
              title="选择处理方案"
            />
          </van-popup>
        </div>
        <!-- 零部件种类和数量 -->
        <template v-if="form.solutionType == 109002">
          <div class="selector">
            <van-field
              readonly
              clickable
              name="solutionValue"
              :value="form.partsValue"
              label="零部件种类和数量"
              placeholder="请选择零部件种类和数量"
              @click-input="partsShowClick"
              :rules="[{ required: true }]"
              :label-width="200"
            />
          </div>
          <van-popup
            v-model="parts.isShow"
            @click-overlay="onCloseParts"
            position="bottom"
            :style="{ height: '81%' }"
          >
            <div class="parts">
              <div class="parts-header">
                <div class="cancel" @click="onCloseParts">取消</div>
                <div class="confirm active-color" @click="onConfirmParts">确定</div>
              </div>
              <div class="header">
                <div class="searchBarInner">
                  <div class="inputWrapper">
                    <img class="searchIcon" src="@/assets/imgs/input-search.png" alt="" />
                    <input ref="ipt" type="text" v-model="value" placeholder="请输入零部件种类" />
                    <img
                      v-if="showClear"
                      @click="clrearValueClick"
                      class="clearIcon"
                      src="@/assets/imgs/input-clear.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="parts-list" ref="partsList">
                <div class="stepper-box" v-for="(item, index) in typeArr" :key="index">
                  <div class="label" v-html="hightName(item.dictTypeValue)"></div>
                  <van-stepper v-model="item.dictTypeNum" min="0" disable-input />
                </div>
                <div class="noData" v-if="typeArr.length === 0 && value">
                  <img src="@/assets/imgs/no_data.png" alt="" />
                  <span>无搜索结果</span>
                </div>
              </div>
            </div>
          </van-popup>
        </template>
        <!-- 事故赔偿金额 -->
        <div
          class="compensation"
          ref="compensation"
          v-if="
            form.faultLiabilityType == 108006 ||
            form.faultLiabilityType == 108007 ||
            form.faultLiabilityType == 108005
          "
        >
          <van-field
            name="compensation"
            v-model="form.Compensation"
            label="事故赔偿金额"
            autosize
            type="digit"
            placeholder="请输入赔偿金额"
            maxlength="7"
            :label-width="200"
            class="compensation"
            @focus="handleInputFocus('compensation')"
            @blur="handleInputBlur"
            :rules="[{ required: false }]"
          />
          <div class="unit">元</div>
        </div>
        <!-- 方案描述 -->
        <div class="textDes" ref="solutionDes">
          <van-field
            name="solutionDes"
            v-model.trim="form.solutionDes"
            rows="3"
            autosize
            label="方案描述"
            type="textarea"
            maxlength="500"
            placeholder="具体描述故障处理方案，最多500字"
            @focus="handleInputFocus('solutionDes')"
            @blur="handleInputBlur"
            :rules="[{ required: true }]"
          />
        </div>
        <!-- 上传/拍摄图片 -->
        <div class="upload">
          <div class="top">
            <span>故障图片/视频</span>
            <span>(最多6张)</span>
          </div>
          <div class="pic">
            <van-uploader
              v-model="fileList"
              :after-read="onAfterRead"
              :before-read="onBeforeRead"
              :before-delete="onDelete"
              ref="uploader"
              :max-count="6"
              :accept="acceptType"
              class="pic-upload"
            >
              <!-- :accept="acceptType" -->
              <div v-if="fileList.length < 6" class="uploadTrigger">
                <img
                  class="needsclick"
                  @click="handleUploadClick"
                  src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/uploader.png"
                  alt=""
                />
              </div>
            </van-uploader>
          </div>
        </div>
      </van-form>
    </div>
    <!-- 上报故障按钮 -->
    <div class="bottomBtn submitBtn" v-show="hideshow">
      <div
        v-if="submitDisabled || (form.solutionType == 109002 && !!!form.partsValue)"
        class="disabled"
      >
        完成诊断
      </div>
      <div v-else @click="handleFaultReport">完成诊断</div>
    </div>
  </div>
</template>
<script>
import { diagnosisSuccessFlag } from '@/utils/index';
import fetchRequest from '@/utils/fetchRequest';
import NDialog from '@/components/NDialog';
import { requestCameraPermission } from '@/utils';
import moment from 'moment';
import { Toast } from 'vant';
import TimePicker from '@/components/TimePicker.vue';
import {
  uploadPicture,
  getFaultDict,
  getFaultOrderDetail,
  saveOrUpdateFaultDiagnosis,
} from '@/api/apiv2';
import { param2Obj } from '@/utils';
export default {
  name: '',
  components: {
    TimePicker,
  },
  data() {
    return {
      value: '', // 搜索内容
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      headerH: 48,
      // 模块故障
      moduleColumns: [],
      showPickerModule: false,
      // 主故障
      mainfaultColumns: [],
      showPickerMainfault: false,
      // 故障责任判定
      faultLiability: {
        isShow: false,
        columns: [
          { id: 1, name: '责任判定1' },
          { id: 2, name: '责任判定2' },
        ],
      },
      solution: {
        isShow: false,
        columns: [
          { id: 1, name: '方案1' },
          { id: 2, name: '方案2' },
        ],
      },
      parts: {
        isShow: false,
        // canopyVal: 0, // 雨棚
        // hydraulicRodVal: 0, // 液压杆
        // tyreVal: 0, // 轮胎
        // mcuVal: 0, // MCU
      },
      fileList: [], // 上传文件
      form: {
        timeValue: moment().format('YYYY-MM-DD HH:mm:ss'), //修改后的时间
        moduleType: '', // 故障类型
        moduleTypeValue: '',
        mainfaultType: '', // 主故障
        mainfaultValue: '',
        faultLiabilityValue: '', // 故障责任判定
        faultLiabilityType: '',
        solutionValue: '', // 处理方案
        solutionType: '',
        message: '', //具体描述信息
        solutionDes: '', //方案描述
        partsValue: '',
        Compensation: null,
        // canopyVal: 0, // 雨棚
        // hydraulicRodVal: 0, // 液压杆
        // tyreVal: 0, // 轮胎
        // mcuVal: 0, // MCU
      },
      btnDisabled: true, // 上报故障按钮
      vinId: '',
      vin: '',
      parkName: '',
      fileArr: [],
      typeArr: [],
      newTypeArr: [],
      faultArrAll: [], //主故障全部数据
      // 故障时间
      timePickerShow: false, //打开/关闭时间选择器
      timeData: moment().format('YYYY-MM-DD HH:mm:ss'), // 进页面的时间

      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true,
      // 故障类型
      showPickerType: false,
      typeValueID: '',
      typeColumns: [],
      moduleArrAll: [], //故障模块全部数据
      acceptType: 'image/*,video/*',
      faultTypeColumns: [],
      onClosePartsValueList: [],
    };
  },

  computed: {
    submitDisabled() {
      /**
       * 故障类型、故障描述、主故障、故障责任判定、处理方案、方案描述
       */
      return !(
        this.typeValueID &&
        this.form.message &&
        this.form.mainfaultValue &&
        this.form.faultLiabilityValue &&
        this.form.solutionValue &&
        this.form.solutionDes
      );
    },
    showClear() {
      return this.value !== '';
    },
    // 废弃
    // partsValue() {
    //   let desArr = [];
    //   const newTypeArr = this.typeArr;
    //   newTypeArr.map((item) => {
    //     if (item.dictTypeNum && item.dictTypeNum > 0) {
    //       desArr.push(`${item.dictTypeValue}*${item.dictTypeNum}`);
    //     }
    //   });
    //   return desArr.length > 0 ? desArr.join(',') : null;
    // },
  },

  watch: {
    // 当输入框被遮挡时隐藏底部按钮
    showHeight: function () {
      // 奇葩问题
      if (this.docmHeight > this.showHeight + 2) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
    // form: {},
    value: function (val) {
      // console.log(val);
      this.setNewTypeArr();
      this.typeArr = this.newTypeArr.filter((v) => {
        return ~v.dictTypeValue.indexOf(this.value);
      });
    },
  },

  mounted: function () {
    this.getFaultDictServer();

    const query = param2Obj(location.href);
    if (query.id) {
      this.$nextTick(() => {
        this.getDetail(query.id);
      });
    }

    window.cameraPermission = () => {
      this.clicked = true;
      const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
      uploaderIpt.click();
    };

    window.addEventListener('resize', () => {
      this.showHeight = document.body.clientHeight;
    });
  },

  created: function () {
    //这里定义窗口的原始高度
    this.oldHeight = document.documentElement.clientHeight;
  },

  beforeDestroy() {
    window.cameraPermission = null;
  },

  methods: {
    // 搜索高亮文字
    hightName(val) {
      let str = this.value;
      let replaceName = `<span class='hight-keyword'>${str}</span>`;
      var reg = new RegExp(str, 'g');
      let result = val.replace(reg, replaceName);
      return result;
    },
    setNewTypeArr() {
      this.newTypeArr.forEach((item) => {
        this.typeArr.forEach((ite) => {
          if (item.dictType == ite.dictType) {
            item.dictTypeNum = ite.dictTypeNum;
          }
        });
      });
    },
    // 清空value
    clrearValueClick() {
      this.value = '';
      this.setNewTypeArr();
      this.$nextTick(() => {
        this.typeArr = JSON.parse(JSON.stringify(this.newTypeArr));
      });
    },
    async getDetail(id) {
      const res = await getFaultOrderDetail({ id });
      this.$nextTick(() => {
        this.setData(res);
      });
    },
    async setData(res) {
      const {
        vinId,
        parkName,
        faultType,
        faultTypeValue,
        faultTime,
        faultDesc,
        liabilityType,
        liabilityValue,
        mainFaultType,
        mainFaultValue,
        compensationAmount,
        solutionDesc,
        solutionType,
        solutionTypeValue,
        faultPartDTOList,
      } = res;
      if (vinId) this.vinId = vinId;
      if (parkName) this.parkName = parkName;
      if (faultType) this.form.moduleType = faultType; // 故障类型
      if (faultType) {
        this.form.moduleTypeValue = faultTypeValue; // 故障类型名称
        const res = await getFaultDict({ typeCode: null });
        res['10'].forEach((item) => {
          if (item.dictType == faultType) {
            this.onConfirmType({ ...item, isType: true });
          }
        });
      }
      if (faultTime) this.form.timeValue = faultTime; // 修改后的时间
      if (liabilityType) this.form.faultLiabilityType = liabilityType; // 故障责任判定
      if (liabilityValue) this.form.faultLiabilityValue = liabilityValue; // 故障责任判定名称
      if (mainFaultType) this.form.mainfaultType = mainFaultType; // 主故障
      if (mainFaultValue && mainFaultType) this.form.mainfaultValue = mainFaultValue; // 主故障名称
      if (solutionType) this.form.solutionType = solutionType; //  处理方案
      if (solutionTypeValue) this.form.solutionValue = solutionTypeValue; // 处理方案名称
      if (faultPartDTOList && faultPartDTOList.length > 0) {
        this.typeArr.map((item1) => {
          faultPartDTOList.map((item2) => {
            if (item1.dictType == item2.partCategory) {
              item1.dictTypeNum = item2.quantity;
            }
          });
        });
        this.onClosePartsValueList = this.newTypeArr = JSON.parse(JSON.stringify(this.typeArr));
        this.$nextTick(() => {
          this.setPartsValue();
        });
      }
      if (!!compensationAmount || compensationAmount == 0)
        this.$set(this.form, 'Compensation', compensationAmount); // 事故赔偿金额
      if (solutionDesc) this.form.solutionDes = solutionDesc; // 方案描述
      if (faultDesc) this.form.message = faultDesc;
      res.imageUrls.length > 0 &&
        res.imageUrls.forEach((item) => {
          this.fileList.push({
            url: item,
          });
          this.fileArr.push(item);
        });
      this.$forceUpdate();
    },
    confirmOn(val) {
      if (moment(this.timeData).diff(moment(val)) < 0) {
        return Toast({
          message: '不可超过当前时间',
          className: 'response-toast',
          forbidClick: true,
        });
      }
      this.timePickerShow = false;
      this.form.timeValue = val;
    },

    // 获得所有的故障枚举值
    async getFaultDictServer() {
      const res = await getFaultDict({ typeCode: null });
      this.faultTypeColumns = res['10'];
      this.mainfaultColumns = res['20'];
      this.faultLiability.columns = res['30'];
      this.solution.columns = res['40'];
      this.typeArr = res['40'].find((item) => item.dictType == 109002).childFaultDictVO;
      this.typeArr.map((item) => {
        this.$set(item, 'dictTypeNum', 0);
      });
      this.onClosePartsValueList = this.newTypeArr = JSON.parse(JSON.stringify(this.typeArr));
    },

    // 返回
    async goback() {
      if (window.location.href.indexOf('//localhost:') > -1) {
        this.$router.go(-1);
        return;
      }
      window.location.href = 'neolix://more';
    },

    // 点击上报故障
    async handleFaultReport() {
      const result = await NDialog.confirm({
        message: '确定完成故障诊断吗？',
        isLoding: true,
      });
      if (result === 'confirm') {
        this.createTicket();
      }
    },

    // 创建工单
    async createTicket() {
      const faultPartDTOList = this.typeArr.filter((item) => {
        return item.dictTypeNum > 0;
      });
      let params = {
        timeValue: this.form.timeValue,
        faultTime: this.form.timeValue,
        faultOrderId: this.$route.query.id,
        faultType: this.form.moduleType, //故障类型   字典10里返 回dictType
        faultTypeValue: this.form.moduleTypeValue,
        faultDesc: this.form.message, //故障描述
        mainFaultType: this.form.mainfaultType, //主故障类型   字典传20里返回dictType
        mainFaultValue: this.form.mainfaultValue, //主故障值   字典传20里返回dictTypeValue
        liabilityType: this.form.faultLiabilityType, //故障责任判定类型 字典传30里返回dictType
        liabilityValue: this.form.faultLiabilityValue, //故障责任判定值     字典传30里返回dictTypeValue
        solutionType: this.form.solutionType, //解决方案类型     字典传40里返回dictType
        solutionTypeValue: this.form.solutionValue,
        solutionDesc: this.form.solutionDes, //方案描述
        compensationAmount: this.form.Compensation, //补偿金额
        imageUrls: this.fileArr,
        faultPartDTOList: faultPartDTOList.map((item) => {
          return {
            partCategory: item.dictType,
            quantity: item.dictTypeNum,
          };
        }),
      };
      await saveOrUpdateFaultDiagnosis(params);
      Toast({
        message: '上报成功',
        className: 'response-toast',
        forbidClick: true,
      });
      NDialog.close();
      setTimeout(() => {
        // window.location.href = 'neolix://interaction';
        const obj = {
          id: Number(this.$route.query.id),
          ...params,
          parkName: this.parkName,
          vinId: this.vinId,
          showDiagnosis: true,
          showTransfer: false,
          showReceive: false,
          showClose: true,
          showDetail: false,
          status: 3,
          statusName: '处理中-已诊断',
        };
        diagnosisSuccessFlag(JSON.stringify(obj));
      }, 1000);
    },
    // 选择故障类型
    onConfirmType({ dictType, dictTypeValue, id, isType }) {
      this.form.moduleTypeValue = dictTypeValue;
      this.form.moduleType = dictType;
      this.typeValueID = id;
      this.showPickerType = false;
      this.form.mainfaultValue = '';
      this.form.mainfaultType = '';
      if (!isType) this.form.message = '';
      this.mainfaultColumns = this.faultTypeColumns.find((item) => item.id === id).childFaultDictVO;
    },
    // 选择主故障
    onConfirmMainfault({ dictType, dictTypeValue }) {
      this.form.mainfaultValue = dictTypeValue;
      this.form.mainfaultType = dictType;
      this.showPickerMainfault = false;
    },
    // 选择故障责任判定
    onConfirmFaultLiability({ dictType, dictTypeValue }) {
      this.form.faultLiabilityValue = dictTypeValue;
      /**
       * 根据故障责任类型dictType 判断是否显示赔偿金额
       * 如果不是当前选择的dictType，则清空赔偿金额
       * 如果是当前选择的dictType，则不做任何操作
       */
      if (this.form.faultLiabilityType !== dictType) {
        this.form.faultLiabilityType = dictType;
        this.form.Compensation = '';
      }
      this.faultLiability.isShow = false;
    },
    onConfirmSolution({ dictType, dictTypeValue }) {
      this.form.solutionValue = dictTypeValue;
      /**
       * 根据事故方案类型id 判断是否零部件种类和数量
       * 如果不是当前选择的id，则清空零部件种类和数量
       * 如果是当前选择的id，则不做任何操作
       */
      if (this.form.solutionType != dictType) {
        this.form.solutionType = dictType;
        this.typeArr.map((item) => {
          this.$set(item, 'dictTypeNum', 0);
        });
        this.form.partsValue = '';
      }
      this.solution.isShow = false;
    },
    // 零部件选择
    partsShowClick() {
      this.parts.isShow = true;
    },
    onCloseParts() {
      this.parts.isShow = false;
      this.handleScrollTop();
      setTimeout(() => {
        this.value = '';
        this.typeArr = JSON.parse(JSON.stringify(this.onClosePartsValueList));
      }, 100);
    },
    setPartsValue() {
      this.setNewTypeArr();
      const arrList = this.newTypeArr.sort((a, b) => {
        return b.dictTypeNum - a.dictTypeNum;
      });
      this.typeArr = this.newTypeArr = JSON.parse(JSON.stringify(arrList));
      let desArr = [];
      this.typeArr.map((item) => {
        if (item.dictTypeNum && item.dictTypeNum > 0) {
          desArr.push(`${item.dictTypeValue}*${item.dictTypeNum}`);
        }
      });
      this.form.partsValue = desArr.length > 0 ? desArr.join(',') : null;
    },
    onConfirmParts() {
      this.parts.isShow = false;
      this.handleScrollTop();
      setTimeout(() => {
        this.clrearValueClick();
        this.setPartsValue();
        this.onClosePartsValueList = JSON.parse(JSON.stringify(this.newTypeArr));
      }, 100);
    },
    onAfterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';
      this.uploadPictureToWorkOrder(file);
      // this.uploadPictureToAD(file);
    },
    // AD上传图片
    uploadPictureToAD(file) {
      fetchRequest('obs/upload/token', {
        method: 'GET',
        data: {
          uploadType: 'FAULT_REPORT_FORM',
        },
      }).then((data) => {
        let number = this.generateRandomHex(16);
        const formData = new FormData();
        const reNamedFile = new File([file.file], number + file.file.name, {
          type: file.file.type,
        });

        // 兼容三个云 https://r3c0qt6yjw.feishu.cn/wiki/GowBwcc1uiuvLmkthogcbPX3nQb
        switch (data.type) {
          case 'ZOS':
            formData.append('key', data.uploadDir + number + file.file.name);
            // formData.append('content-type', 'text/plain');
            formData.append('Policy', data.policy);
            formData.append('AWSAccessKeyId', data.accessKeyId);
            formData.append('signature', data.signature);
            break;
          case 'EOS':
            formData.append('key', data.uploadDir + number + file.file.name);
            // formData.append('content-type', 'text/plain');
            formData.append('Policy', data.policy);
            // formData.append('AWSAccessKeyId', data.accessKeyId);
            formData.append('X-Amz-Signature', data.signature);
            formData.append('X-Amz-Algorithm', data.amzAlgorithm);
            formData.append('X-Amz-Credential', data.amzCredential);
            formData.append('X-Amz-Date', data.amzDate);
            break;
          default:
            formData.append('key', data.uploadDir + number + file.file.name);
            formData.append('content-type', 'text/plain');
            formData.append('policy', data.policy);
            formData.append('AccessKeyId', data.accessKeyId);
            formData.append('signature', data.signature);
        }
        formData.append('file', reNamedFile);

        fetchRequest(data.action.replace('http:', 'https:'), {
          method: 'POST',
          data: formData,
          isUrl: false,
        }).then(() => {
          // console.log(data);
        });
        this.fileArr.push(number + file.file.name);
      });
    },
    // 自定义工单创建图片
    async uploadPictureToWorkOrder(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      const url = await uploadPicture(formData);
      this.fileArr.push(url);
      file.status = '';
      file.message = '';
    },
    onBeforeRead(file) {
      let status = true;
      if (!file.type.includes('video/') && !file.type.includes('image/')) {
        Toast({
          message: '请上传图片、视频',
          className: 'response-toast',
          forbidClick: true,
        });
        status = false;
        return false;
      }
      if (file.size > 10 * 1000 * 1024) {
        Toast({
          message: `请上传10MB以内的${file.type.includes('video/') ? '视频' : '图片'}`,
          className: 'response-toast',
          forbidClick: true,
        });
        status = false;
        return false;
      }
      return status;
    },
    generateRandomHex(length) {
      var result = '';
      var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    handleUploadClick() {
      if (process.env.NODE_ENV === 'development') {
        const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
        uploaderIpt.click();
      }
      requestCameraPermission();
    },

    debugInLocal() {
      location.href =
        // "http://192.168.1.6:8080/FaultReporting?vin=LHTAG2A27NY9PA007&vinId=X3P269&token=1bee7d34-e774-4d92-8bee-164a59a51701&userId=2004&realName=用户5782&parkName=北京恒通商务园&parkCode=010105&uuidWeb=c491a698-fbe3-4992-a852-a9bfb12bb6c3";
        'http://172.16.5.4:8080/FaultReporting?vin=LHTBW2B21RY2UA010';
    },

    onDelete(file, detail) {
      this.fileArr.splice(detail.index, 1);
      this.fileList.splice(detail.index, 1);
    },
    // 零部件种类滑动到顶部
    handleScrollTop() {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      // 区分iOS 和 安卓
      if (isIOS) {
        setTimeout(() => {
          this.$refs.partsList.scrollTop = 0;
        }, 100);
      } else {
        // scroll 在iOS的兼容性不好
        this.$refs.partsList.scrollTop = 0;
      }
    },

    handleInputBlur() {},
    handleInputFocus(refName) {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      const offsetTop = this.$refs[refName].offsetTop;
      // 区分iOS 和 安卓
      if (isIOS) {
        // iOS主要使用scrollTop，且需要加上延迟
        // document.getElementByClassName iOS容易获取不到dom
        setTimeout(() => {
          // this.$refs.contentBox.scrollTop = this.docmHeight / 2;
          this.$refs.contentBox.scrollTop = offsetTop - 190;
        }, 200);
      } else {
        // scroll 在iOS的兼容性不好
        this.$refs.contentBox.scroll({
          top: offsetTop - 190,
          left: 0,
          behavior: 'smooth',
        });
      }
    },

    // 判断是iOS还是安卓限制相机显示，因为安卓会出现两个相机
    acceptHandle() {
      let u = navigator.userAgent;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isIOS) {
        // ios 显示视频录制也可上传视频, 防止下一个需求再次添加
        // this.acceptType = '*'
        this.acceptType = 'image/*,video/*';
      } else {
        // 安卓不显示录屏。只有拍照，但是可上传视频
        this.acceptType = 'image/*,video/*';
      }
    },
  },
};
</script>
<style>
body,
html {
  background-color: #fff !important;
}
</style>
<style scoped lang="scss">
#contentBox {
  height: 100%;
  overflow: scroll;
}

.box-faultr {
  width: 100%;
  // height: 100vh;
  font-size: initial;
  background: #fff;
  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: white;
    top: 0;
    .navBar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;

      .back {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        // top: 0.07rem;
        left: 0.2rem;
        z-index: 3;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 48px;
    background: rgba(245, 246, 250, 1);
  }
  .top-vin {
    width: 100%;
    padding: 16px 20px;
    background: #ffffff;
    .text {
      font-size: 17px;
      color: #000000;
      margin-bottom: 6px;
      font-weight: 600;
      .vin {
        font-size: 20px;
      }
    }
    .location {
      margin-right: 2px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: rgba(246, 247, 248, 1);
  }
  .content {
    width: 100%;
    // margin-top: 10px;
    padding-bottom: 94px;
    .child-picker-dis {
      ::v-deep .van-cell {
        input {
          border: none;
          background: rgba(250, 251, 252, 1);
          background-image: url('../../assets/faultrReporting/no-check-arrow.png');
          background-position: 98% 50%;
          background-repeat: no-repeat;
          background-size: 18px;
        }
        .van-field__control:disabled {
          color: rgba(213, 214, 221, 1);
          -webkit-text-fill-color: rgba(213, 214, 221, 1);
        }
      }
    }

    ::v-deep(.van-cell) {
      flex-direction: column;
      &::after {
        border-bottom: none;
      }
      .van-field__label {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        &::before {
          content: '*';
          position: absolute;
          top: 23%;
          transform: translateY(-50%);
          color: rgba(255, 57, 27, 1);
        }
        span {
          margin-left: 10px;
        }
      }
      input {
        width: 100%;
        height: 48px;
        opacity: 1;
        border-radius: 6px;
        background: #f4f7f9;
        border: 1px solid #eaeeee;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 12px;
        text-overflow: ellipsis;
        padding-right: 20px;
        box-sizing: border-box;
      }
      textarea {
        width: 100%;
        height: 96px !important;
        opacity: 1;
        border-radius: 6px;
        background: #f4f7f9;
        border: 1px solid #eaeeee;
        padding: 13px 12px;
        margin-top: 12px;
        font-size: 16px;
      }
    }
    .compensation {
      ::v-deep(.van-cell) {
        .van-field__label {
          &::before {
            content: '';
          }
        }
      }
    }
    .selector {
      ::v-deep(.van-cell) {
        input {
          width: 100%;
          height: 48px;
          opacity: 1;
          border-radius: 6px;
          background: #f4f7f9;
          border: 1px solid #eaeeee;
          margin-top: 12px;
          font-size: 16px;
          font-weight: 400;
          padding-left: 12px;
          background-image: url('../../assets/faultrReporting/arrow-down.png');
          background-position: 98% 50%;
          background-repeat: no-repeat;
          background-size: 18px;
        }
      }
    }
    .time-picker {
      ::v-deep .van-cell {
        input {
          background-image: url('../../assets/faultrReporting/time.png');
          background-position: 98% 50%;
          background-repeat: no-repeat;
          background-size: 18px;
        }
      }
    }
    ::v-deep .van-cell--clickable:active {
      background-color: #fff;
    }
    .compensation {
      position: relative;
      .unit {
        position: absolute;
        top: 58px;
        right: 28px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
    .textDes {
      ::v-deep .van-cell {
        .van-field__label {
          &::before {
            content: '*';
            position: absolute;
            top: 15%;
          }
        }
      }
    }
    .upload {
      width: 100%;
      background: #ffffff;
      padding: 20px 16px;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: rgba(0, 0, 0, 1);
        position: relative;
        &.required {
          padding-left: 10px;
          &::before {
            content: '*';
            position: absolute;
            left: 0px;
            top: 15%;
            color: rgb(255, 57, 27);
          }
        }

        > span {
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 600;
          }
          &:nth-child(2) {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .upload-image {
        width: 86px;
        height: 86px;
      }
      .pic {
        .pic-upload {
          ::v-deep .van-uploader__preview {
            margin: 0 15px 15px 0;
          }
          ::v-deep .van-uploader__preview-delete {
            background-image: url('https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/item_delete.png');
            background-repeat: no-repeat;
            background-size: 22px;
            background-color: transparent;
            width: 22px;
            height: 22px;
            border-radius: 0;
            top: -9px;
            right: -7px;
          }
          ::v-deep .van-icon-cross:before {
            content: none;
          }
        }

        .deleteUploaded {
          position: absolute;
          left: 0;
          top: 0;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    ::v-deep .van-popup {
      border-radius: 16px 16px 0px 0px;
      .van-picker__toolbar {
        height: 55px;
        .van-picker__cancel {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
        .van-picker__confirm {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #436eff;
          letter-spacing: 0;
          font-weight: 400;
        }
        .van-picker-column__item {
          padding: 0 10px;
        }
      }
    }
  }
  .bottomBtn {
    background: #fff;
    position: fixed;
    bottom: 0;
    padding: 24px 24px;
    box-sizing: border-box;
    width: 100%;

    &.submitBtn {
      > div {
        height: 46px;
        line-height: 46px;
        background: rgba(67, 110, 255, 1);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 23px;
        &.disabled {
          background: rgba(203, 213, 251, 1);
        }
      }
    }

    &.editBtn {
      > div {
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(67, 110, 255, 1);
        border-radius: 23px;
        border: 2px solid rgba(67, 110, 255, 1);
        box-sizing: border-box;
      }
    }
  }
}

.uploadTrigger {
  margin-top: 0px;
  width: 86px;
  height: 86px;
  img {
    width: 86px;
    height: 86px;
    pointer-events: all;
  }
}

::v-deep .van-uploader__input-wrapper {
  pointer-events: none;
}
.top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 1);
  position: relative;
  &.required {
    padding-left: 10px;
    &::before {
      content: '*';
      position: absolute;
      left: 0px;
      top: 15%;
      color: rgb(255, 57, 27);
    }
  }
}
.parts {
  position: relative;
  touch-action: pan-y;
  height: 100%;
  .parts-header {
    width: 100%;
    height: 42px;
    background: #fafafa;
    border-bottom: 1px solid #ebebeb;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding: 0 20px;
    box-sizing: border-box;
    .confirm {
      color: rgba(70, 95, 253, 1);
    }
  }
  .header {
    position: absolute;
    top: 52px;
    width: 100%;
    height: 46px;
    padding: 0 20px 0 16px;
    .searchBarInner {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .inputWrapper {
        flex-grow: 1;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: rgba(246, 247, 248, 1);

        border-radius: 6px;

        .searchIcon {
          width: 14px;
          height: 14px;
          margin-left: 9px;
          margin-right: 8px;
        }

        input {
          height: 32px;
          flex-grow: 1;
          font-size: 16px;
          color: #000000;
          caret-color: #436eff;
          background: rgba(246, 247, 248, 1);
          &::placeholder {
            font-size: 14px;
            color: #bfc0cb;
            font-weight: normal;
          }
        }

        .clearIcon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 7px;
          right: 8px;
        }
      }
    }
  }
  .parts-list {
    position: absolute;
    top: 92px;
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
    .stepper-box {
      width: 100%;
      // height: 48px;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(235, 235, 235, 1);
      .label {
        width: 193px;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
      ::v-deep .van-stepper {
        button,
        input {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        input {
          background: white;

          width: 60px;
        }
        button {
          background: rgba(244, 247, 249, 1);
          border: 1px solid rgba(234, 238, 238, 1);
        }
        .van-stepper__minus--disabled {
          background: rgba(244, 247, 249, 1);
          border: 1px solid rgba(234, 238, 238, 1);
        }
      }
    }
    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 120px;

      img {
        width: 160px;
        height: 160px;
      }

      span {
        font-size: 16px;
        color: #222222;
      }
    }
  }
}
</style>
